import { useState, React } from 'react'
import { Squash as Hamburger } from 'hamburger-react'
import { Link } from 'react-router-dom'

import { IoIosMail } from 'react-icons/io'
import { FaLinkedin, FaGithub } from 'react-icons/fa'
// import { FaLinkedin } from "react-icons/fa";

import { IconContext } from 'react-icons'

import cvFile from '../../assets/AarunJassalCV.pdf'
import resumeFile from '../../assets/AarunJassalResume.pdf'

import './Header.scss'


function Header({ clientInNorthAmerica }) {

  const [menuOpen,setMenuOpen] = useState(false)

  // function toggleMenuOpen() {
  //   setMenuOpen(!menuOpen)
  // }

  console.log(menuOpen)

  function onCvButtonClick() {
    const link = document.createElement("a")
    if (clientInNorthAmerica) {
      link.href = resumeFile
      link.download = "Aarun Jassal Resume.pdf"
    } else {
      link.href = cvFile
      link.download = "Aarun Jassal CV.pdf"
    }
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setMenuOpen(false)
  }

  return (
    <>
      <section className={`sidebar__container${ menuOpen ? '--active' : '' }`}>
        <nav className='sidebar__nav'>
          <div className='sidebar__nav-item'><Link to='/' onClick={() => setMenuOpen(false)} > Home</Link></div>
          <div className='sidebar__nav-item'><p onClick={onCvButtonClick} >{clientInNorthAmerica ? 'Resume' : 'CV'}</p></div>
          <div className='sidebar__nav-item'><a href='mailto:aaruntj@gmail.com' target='_blank'><IoIosMail size='2.25rem' /></a></div>
          <div className='sidebar__nav-item'><a href='https://www.linkedin.com/in/aarun-jassal/' target='_blank'><FaLinkedin size='2rem' /></a></div>
          <div className='sidebar__nav-item'><a href='https://github.com/aaruntj' target='_blank'><FaGithub size='2rem' /></a></div>

        </nav>
      </section>
      <header className='header'>
        {/* <h1>One Heady Boi</h1> */}



        <button className={`header__hamburger-button${menuOpen ? '--active' : '' }`}>
          <Hamburger
            toggled={menuOpen}
            toggle={setMenuOpen}
            size={30}
            color='white'
          />
          </button>
          

          {/* <Sidebar /> */}
        
        {/* <button
          className='hamburger__button'
          onClick={toggleMenuOpen}
        >
          <svg
            className='hamburger__svg'
            viewBox='0 0 100 100'
          // width="50"
          >
            <rect className={`hamburger__svg-top${menuOpen ? '--menuopen' : ''}`} width='80' height='10' x='10' y='25' rx='5' />
            <rect className={`hamburger__svg-middle${menuOpen ? '--menuopen' : ''}`} width='80' height='10' x='10' y='45' rx='5' />
            <rect className={`hamburger__svg-bottom${menuOpen ? '--menuopen' : ''}`} width='80' height='10' x='10' y='65' rx='5' />
          </svg>
        </button> */}


        {/* <button
          className='hamburger__button'
          onClick={toggleMenuOpen}      
        >
          <svg
            className='hamburger__svg'
            viewBox='0 0 100 100'
          >

            <line
              className={`hamburger__svg-top${menuOpen ? '--menuopen' : ''}`}
              x1='15' x2='85' y1='30' y2='30'
              stroke='black'
              strokeWidth='10'
              strokeLinecap='round'
            />

            <line
              className={`hamburger__svg-middle${menuOpen ? '--menuopen' : ''}`}
              x1='15' x2='85' y1='50' y2='50'
              stroke='black'
              strokeWidth='10'
              strokeLinecap='round'
            />

            <line
              className={`hamburger__svg-bottom${menuOpen ? '--menuopen' : ''}`}
              x1='15' x2='85' y1='70' y2='70'
              stroke='black'
              strokeWidth='10'
              strokeLinecap='round'
            />
              
          </svg>

        </button> */}
        



        <nav className='header__nav'>
          <ul className='header__navlist'>
            <li className='header__navlist-item'><Link to='/'>Home</Link></li>
            <li className='header__navlist-item'><Link to={clientInNorthAmerica ? '/resume' : '/cv'}>{clientInNorthAmerica ? 'Resume' : 'CV'}</Link></li>
            <li className='header__navlist-item'><a href='mailto:aaruntj@gmail.com' target='_blank'><IoIosMail size='2.25rem' /></a></li>            
            <li className='header__navlist-item'><a href='https://www.linkedin.com/in/aarun-jassal/' target='_blank'><FaLinkedin size='2rem' /></a></li>
            <li className='header__navlist-item'><a href='https://github.com/aaruntj' target='_blank'><FaGithub size='2rem' /></a></li>                      
          </ul>
        </nav>
        
      </header>
    </>
  )
}

export default Header