import './CvPage.scss'

import cvFile from '../../assets/AarunJassalCV.pdf'
import resumeFile from '../../assets/AarunJassalResume.pdf'

function CvPage({ clientInNorthAmerica }) {

  // function onCvButtonClick() {
  //   let cvUrl = ""
  //   if (clientInNorthAmerica) {
  //     cvUrl = "Aarun Jassal Resume.pdf"
  //     // setCvUrl("Aarun Jassal Resume.pdf")
  //   } else {
  //     cvUrl = "Aarun Jassal CV.pdf"
  //     // setCvUrl("Aarun Jassal CV.pdf")
  //   }
  //   console.log(cvUrl)
  //   const link = document.createElement("a")
  //   link.href = cvUrl
  //   link.download = cvUrl
  //   document.body.appendChild(link)
  //   link.click()
  //   document.body.removeChild(link)
  // }

  function onCvButtonClick() {
    const link = document.createElement("a")
    if (clientInNorthAmerica) {
      link.href = resumeFile
      link.download = "Aarun Jassal Resume.pdf"
    } else {
      link.href = cvFile
      link.download = "Aarun Jassal CV.pdf"
    }
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
    <div className='cvpage__container'>
      {/* <h3>{clientInNorthAmerica === true ? "Resume" : clientInNorthAmerica === false ? "CV" : "Loading"}</h3> */}
      <button className='cvpage__button' disabled={clientInNorthAmerica === undefined || clientInNorthAmerica === null} onClick={onCvButtonClick}>
        {clientInNorthAmerica === true ? "Download My Resume" : clientInNorthAmerica === false ? "Download my CV" : "Loading"}
      </button>
      {/* <object
        data='public/Aarun Jassal CV.pdf'
        className='page_object'
        // width='10'
        // height='10'
        type='application/pdf'
      /> */}
      <iframe
        // src={cvFile+'#view=FitH'}
        src={cvFile}
        // src='%PUBLIC_URL%/AarunJassalCV.pdf'
        className='cvpage__cv-object'
        // width='10'
        // height='10'
        type='application/pdf'
      />
    </div>
    
    </>
  )
}

export default CvPage