import "./HomePage.scss"

const reactLink = "https://"
const vanillaJsLink = "http://"

const projectData = [
  {
    name: "Art Gallery",
    // description: "ree first react group project",
    description: "A one-day group project using AI to randomly generate images",
    keywords: ["React", "Node.js", "Express.js", "Agile Methodology"],
    link: "artgallery.aarunjassal.com",
    repo: [
      "https://github.com/aaruntj/art-gallery-react-front-end",
      "https://github.com/aaruntj/art-gallery-react-api"
    ],
    disableCard: false
  },
  {
    name: "Band Site",
    description: "Simple website advertising a local band",
    keywords: ["HTML", "SCSS"],
    link: "bandsite.aarunjassal.com",
    repo: "https://github.com/aaruntj/band-site-js",
    disableCard: false
  },
  {
    name: "Coffee Shop Site CSS NEEDS TRANSFERRING",
    description: "ree",
    keywords: [],
    link: "coffeeshopsite.aarunjassal.com",
    repo: "https://github.com/aaruntj/coffee-shop-site-js",
    disableCard: true
  },
  {
    name: "Fire Tracker TAKE A LOOK AT THE GOOGLE MAPS PLUGIN",
    description: "ree",
    keywords: ["NASA API"],
    link: "firetracker.aarunjassal.com",
    repo: "https://github.com/aaruntj/fire-tracker-js",
    disableCard: true
  },
  {
    name: "Music Studio",
    description: "ree",
    keywords: [],
    link: "/music-studio",
    repo: "ree",
    disableCard: true
  },
  {
    name: "Travel Site",
    description: "Simple website advertising dream holiday destinations",
    keywords: ["HTML","SCSS"],
    link: "travelsite.aarunjassal.com",
    repo: "https://github.com/aaruntj/travel-site-js",
    disableCard: false
  },
  {
    name: "Video Site",
    description: "Youtube clone",
    keywords: ["React", "Node.js"],
    link: "videosite.aarunjassal.com",
    repo: [
      "https://github.com/aaruntj/video-site-react-front-end",
      "https://github.com/aaruntj/video-site-react-api"
    ],
    disableCard: false
  },
  {
    name: "Warehouse Inventory Manager",
    description: "Simple Inventory manager for warehouses",
    keywords: ["React"],
    link: "stockmanager.aarunjassal.com",
    repo: [
      "https://github.com/aaruntj/stock-manager-react-front-end",
      "https://github.com/aaruntj/stock-manager-react-api"
    ],
    disableCard: false
  },
  {
    name: "WNBA Industry Project",
    description: "ree",
    keywords: [],
    link: "/wnba-project",
    repo: "ree",
    disableCard: true
  }
]

const testData = [
  {
    name: "Warehouse Inventory Manager",
    description: "ree",
    keywords: [],
    link: "/warehouse-app",
    repo: [
      "siteone",
      "sitetwo"
    ],
    disableCard: false
  }
]

// const proficienciesList = ["Agile Development", "Amazon Web Services (AWS)", "API Developement", "CSS", "Document Object Model (DOM)", "Express.js", "Front-end Development", "Git", "Github", "GraphQL", "HTML", "JavaScript", "Microsoft Azure", "Microsoft Office", "Microsoft Visual Studio Code (VSCode)", "MySQL", "Node.js", "Node Package Manager (npm)", "OAuth", "Object-Oriented Programming", "Postman", "Python", "React.js", "REST APIs", "Routers", "SASS/SCSS", "Software Development", "Web Development"]

function ProjectCard({ name, description, link, repo, disableCard }) {

  let projectIsReactApp = false
  if (Array.isArray(repo)) {
    projectIsReactApp = true
  }

  return (
    <>
      {disableCard ? <> </> :
        <>
          <div className="projects__card-container">
            <p className="projects__card-title">{name}</p>
            <p className="projects__card-description">{description}</p>
            <a className="projects__link" href={projectIsReactApp ? reactLink + link : vanillaJsLink + link} target="_blank">Visit Project</a>
            <p className="projects__card-repo">{projectIsReactApp ? "Repositories:" : "Repository:"}</p>

            {projectIsReactApp ?

              // repo.map((e) => (
              //   <a key={e} href={e}>{e}</a>
              // ))

              <>
                <div className="projects__link-container">
                  <a className="projects__link" href={repo[0]} target='_blank'>View Front End Source Code</a>
                  <a className="projects__link" href={repo[1]} target='_blank'> View API Source Code</a>
                </div>
              </>

              : <a className="projects__link" href={repo} target='_blank'>View Source Code</a>
            }
          </div>
          
        </>
      }
    </>
  )
}

function HomePage() {

  // function onCvButtonClick() {
  //   let cvUrl = ""
  //   if (clientInNorthAmerica) {
  //     cvUrl = "Aarun Jassal Resume.pdf"
  //     // setCvUrl("Aarun Jassal Resume.pdf")
  //   } else {
  //     cvUrl = "Aarun Jassal CV.pdf"
  //     // setCvUrl("Aarun Jassal CV.pdf")
  //   }
  //   console.log(cvUrl)
  //   const link = document.createElement("a")
  //   link.href = cvUrl
  //   link.download = cvUrl
  //   document.body.appendChild(link)
  //   link.click()
  //   document.body.removeChild(link)
  // }

  // return (
  //   <div>HomePage</div>
  // )

  return (
    <div className="home__container">
      <div className="home__title-container">
        <h1 className="home__title">Aarun Jassal</h1>
        <h2 className="home__subheading">Full-Stack Web Developer</h2>
      </div>
      {/* <h2 className="home__subheading">Full-Stack Web Developer</h2> */}
      {/* <h2>portfolio homepage - coming soon</h2> */}

      {/* <section>
        <h2>proficiencies</h2>
      </section> */}

      <section className="projects__container">
        <h2 className="projects__heading">Projects</h2>
        <div className="projects__list-container">
          {projectData.map(e =>
            <ProjectCard
              key={e.name}
              name={e.name}
              description={e.description}
              link={e.link}
              repo={e.repo}
              disableCard={e.disableCard}
            />
          )}
        </div>
      </section>

      {/* <section>
        <h3>{clientInNorthAmerica === true ? "Resume" : clientInNorthAmerica === false ? "CV" : "Loading"}</h3>
        <button disabled={clientInNorthAmerica === undefined || clientInNorthAmerica === null} onClick={onCvButtonClick}>
          {clientInNorthAmerica === true ? "Download My Resume" : clientInNorthAmerica === false ? "Download my CV" : "Loading"}
        </button>
      </section> */}

    </div>

  )
}

export default HomePage