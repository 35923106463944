// import logo from './logo.svg';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom';

import Header from './components/Header/Header';
import HomePage from './pages/HomePage/HomePage';
import CvPage from './pages/CvPage/CvPage';
// import ContactPage from './pages/ContactPage/ContactPage';

import './App.scss'



function App() {

  const [clientInNorthAmerica, setClientInNorthAmerica] = useState()

  useEffect(() => {
    async function getCountry() {
      try {
        const response = await axios.get("https://ipapi.co/json")
        console.log(response.data)
        if (response.data.country_name === "United States" || response.data.country_name === "Canada") {
          setClientInNorthAmerica(true)
        } else {
          setClientInNorthAmerica(false)
        }
      } catch(error) {
        console.log("error: ",error)
      }
    }
    getCountry()
  },[])

  function Layout(){
    return (
      <>
        <Header clientInNorthAmerica={clientInNorthAmerica} />
        <Outlet />
      </>
    )
  }

  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <HomePage />
        },
        {
          path: "/cv",
          element: <CvPage clientInNorthAmerica={clientInNorthAmerica} />
        },
        {
          path: "/resume",
          element: <CvPage clientInNorthAmerica={clientInNorthAmerica} />
        },
        // {
        //   path: "/contact",
        //   element: <ContactPage />
        // }
      ]
    },
    {
      path: "*",
      element: <Navigate to="/" />
      // element: <div>Reee</div>
    }
  ])

  return (
    <>
      <RouterProvider router = {router}/>
    </>
  )

}

export default App;
